import { Card, Grid } from 'components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { featuredBlogPostsStyles } from './FeaturedBlogPosts.styles';
import InView from 'react-intersection-observer';
import { usePrismicDocuments } from 'hooks';

export const FeaturedBlogPosts: React.FC = () => {
  const { locale } = useIntl();
  const documents = usePrismicDocuments(locale, 'blog');

  return (
    <section>
      <Grid type="page">
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'circOut', duration: 0.6 }}
              css={featuredBlogPostsStyles.categoryName}
            >
              / <FormattedMessage id="home.blog.category-name" />
            </motion.p>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'circOut', duration: 0.6 }}
              css={featuredBlogPostsStyles.title}
            >
              <FormattedMessage id="home.blog.title" />
            </motion.h2>
          )}
        </InView>
        {documents?.slice(0, 4).map((item, index) => (
          <InView threshold={0.2} triggerOnce={true} key={item.node._meta.uid}>
            {({ ref, inView }) => (
              <motion.article
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? '0' : '40px',
                }}
                transition={{
                  ease: 'circOut',
                  duration: 0.6,
                  delay: index % 2 == 0 ? 0.2 : 0.5,
                }}
                css={
                  index % 2
                    ? featuredBlogPostsStyles.contentWrapper(7, 5)
                    : featuredBlogPostsStyles.contentWrapper(2, 5)
                }
              >
                <Link to={`blog/${item.node._meta.uid}`}>
                  <Card document={item} cardType="blog" />
                </Link>
              </motion.article>
            )}
          </InView>
        ))}
      </Grid>
    </section>
  );
};
