import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const root = css`
  margin-bottom: calc(var(--unit) * 36);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 45);
  }

  @media ${breakpoints.large} {
    margin-bottom: calc(var(--unit) * 58);
  }
`;

const sliderWrapper = css`
  position: relative;
  width: 100%;
  grid-column: 2 / span 10;
  margin-bottom: 0;

  &::after {
    position: absolute;
    content: '';
    height: 2px;
    right: 0;
    width: 80%;
    bottom: calc(0px - var(--spacing-huge) - 3px);
    background-color: ${colors.borderLight};
  }

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--spacing-xxxlrg) + 1px);

    &::after {
      display: none;
    }
  }
`;

const videoWrapper = css`
  height: calc(var(--unit) * 90);
  width: 100%;

  @media ${breakpoints.medium} {
    height: calc(var(--unit) * 152);
  }
`;

const separator = css`
  height: 2px;
  background-color: ${colors.borderLight};
  z-index: -1;
  display: none;

  @media ${breakpoints.medium} {
    grid-column: 3 / span 9;
    display: block;
  }
`;

export const homeVideosStyles = {
  root,
  videoWrapper,
  sliderWrapper,
  separator,
};
