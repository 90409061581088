import { Grid, SliderComponent } from 'components';
import { SingleOffer } from '.';
import React from 'react';
import { offersStyles } from './Offers.style';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-react-intl';
import { useProgramDocuments } from 'hooks';

export const Offers: React.FC = () => {
  const { locale } = useIntl();
  const documents = useProgramDocuments(locale);
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.4 });

  return (
    <section ref={ref}>
      <Grid css={offersStyles.root} type="page">
        <motion.p
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
          transition={{ ease: 'easeIn', duration: 0.3 }}
          css={offersStyles.categoryName}
        >
          / <FormattedMessage id="home.offers.category-name" />
        </motion.p>
        <motion.h2
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
          transition={{ ease: 'easeIn', duration: 0.4, delay: 0.2 }}
          css={offersStyles.title}
        >
          <FormattedMessage id="home.offers.title" />
        </motion.h2>
        <div css={offersStyles.sliderWrapper}>
          {documents && (
            <SliderComponent
              itemsPerSlide={3}
              slidesToScroll={3}
              numberOfItems={6}
            >
              {documents?.slice(0, 6).map((item) => (
                <motion.div
                  key={item.node._meta.uid}
                  initial={{ opacity: 0, y: '40px' }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? '0' : '40px',
                  }}
                  transition={{ ease: 'easeIn', duration: 0.6, delay: 0.4 }}
                  css={offersStyles.offerWrapper}
                >
                  <Link to={`/program/${item.node._meta.uid}`}>
                    <SingleOffer document={item} />
                  </Link>
                </motion.div>
              ))}
            </SliderComponent>
          )}
        </div>
        <div css={offersStyles.separator}></div>
      </Grid>
    </section>
  );
};
