import { Layout } from 'components/layout';
import React from 'react';
import { HomePage } from 'modules/home';
import { SeoRedirect } from 'components/seo-redirect';

const IndexPage: React.FC = () => (
  <Layout isGhostNav={true}>
    <SeoRedirect />
    <HomePage />
  </Layout>
);
export default IndexPage;
