import React from 'react';
import { Grid } from 'components';
import { aboutUsStyles } from './AboutUs.styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useImages } from 'hooks';
import { motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import InView from 'react-intersection-observer';

export const AboutUs: React.FC = () => {
  const { aboutImage } = useImages();
  const aboutImageData = getImage(aboutImage);
  const { formatMessage } = useIntl();

  return (
    <div css={aboutUsStyles.root}>
      <Grid type="page">
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeIn', duration: 0.4 }}
              css={aboutUsStyles.categoryName}
            >
              / <FormattedMessage id="home.about.category-name" />
            </motion.p>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeIn', duration: 0.6 }}
              css={aboutUsStyles.title}
            >
              <FormattedMessage id="home.about.title" />
            </motion.h2>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeIn', duration: 0.6, delay: 0.6 }}
              css={aboutUsStyles.description}
            >
              <FormattedMessage id="home.about.description" />
            </motion.p>
          )}
        </InView>
      </Grid>
      <InView threshold={0.3} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
            transition={{ ease: 'easeIn', duration: 0.8, delay: 0.2 }}
            css={aboutUsStyles.image}
          >
            {aboutImageData && (
              <GatsbyImage
                image={aboutImageData}
                alt={formatMessage({ id: 'images.titles.vineyard' })}
                loading="lazy"
                css={aboutUsStyles.image}
              />
            )}
          </motion.div>
        )}
      </InView>
    </div>
  );
};
