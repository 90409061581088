import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const wrapper = css`
  display: flex;
  flex-direction: column;
`;

const description = css`
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-base);
  margin-bottom: calc(var(--unit) * 3);

  color: ${colors.textLight};

  @media ${breakpoints.medium} {
    font-size: var(--font-size-medium);
    line-height: 30px;
  }
`;

const author = css`
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  color: ${colors.textLight};
`;

export const singleTestimonialStyles = {
  wrapper,
  description,
  author,
};
