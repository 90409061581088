import { Gallery, Grid, SliderComponent } from 'components';
import React, { useEffect, useState } from 'react';
import { curiositiesStyles } from './Curiosities.styles';
import CloseSvg from 'assets/icons/close.svg';
import { useImages } from 'hooks';
import { AnimatePresence, motion } from 'framer-motion';
import InView from 'react-intersection-observer';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

export const Curiosities: React.FC = () => {
  const { galleryContent } = useImages();
  const [isShowClicked, setIsShowClicked] = useState(false);
  const [indexOfImage, setIndexOfImage] = useState(0);
  const numberOfItems = galleryContent.length;

  function handleEsc(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      setIsShowClicked(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <section>
      <AnimatePresence>
        {isShowClicked && (
          <div
            css={curiositiesStyles.galleryWrapper}
            onClick={() => setIsShowClicked(false)}
          >
            <Gallery
              images={galleryContent.map(({ imageTitle, imageUrl }) => {
                return {
                  imageSrc: getSrc(imageUrl),
                  originalAlt: imageTitle,
                };
              })}
              indexOfImage={indexOfImage}
            />
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              css={[
                curiositiesStyles.button,
                curiositiesStyles.galleryButtonClose,
              ]}
              onClick={() => setIsShowClicked(false)}
            >
              <CloseSvg />
            </motion.button>
          </div>
        )}
      </AnimatePresence>
      <Grid css={curiositiesStyles.root} type="page">
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeIn', duration: 0.4 }}
              css={curiositiesStyles.categoryName}
            >
              / <FormattedMessage id="home.curiosities.category-name" />
            </motion.p>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeIn', duration: 0.4, delay: 0.2 }}
              css={curiositiesStyles.title}
            >
              <FormattedMessage id="home.curiosities.title" />
            </motion.h2>
          )}
        </InView>
        <InView threshold={0.3} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeIn', duration: 0.4 }}
              css={curiositiesStyles.sliderWrapper}
            >
              <SliderComponent
                itemsPerSlide={3}
                slidesToScroll={3}
                numberOfItems={numberOfItems}
              >
                {galleryContent.map(({ imageUrl, imageTitle }, index) => {
                  const imageData = getImage(imageUrl);
                  return (
                    <div key={imageUrl}>
                      <motion.div
                        whileHover={{ scale: 1.1, opacity: 0.8 }}
                        transition={{ ease: 'easeOut', duration: 0.3 }}
                        onClick={() => {
                          setIsShowClicked(true);
                          setIndexOfImage(index);
                        }}
                        css={curiositiesStyles.imageWrapper}
                      >
                        {imageData && (
                          <GatsbyImage
                            loading="lazy"
                            image={imageData}
                            alt={imageTitle}
                            css={curiositiesStyles.image}
                          />
                        )}
                      </motion.div>
                      <p css={curiositiesStyles.imageTitle}>{imageTitle}</p>
                    </div>
                  );
                })}
              </SliderComponent>
            </motion.div>
          )}
        </InView>
        <div css={curiositiesStyles.separator}></div>
      </Grid>
    </section>
  );
};
