import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const root = css`
  margin-bottom: calc(var(--unit) * 34);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 45);
  }

  @media ${breakpoints.large} {
    margin-bottom: calc(var(--unit) * 58);
  }
`;

const sliderWrapper = css`
  position: relative;
  width: 100%;
  grid-column: 2 / span 10;
  margin-bottom: 0;

  &::after {
    position: absolute;
    content: '';
    height: 2px;
    right: 0;
    width: 80%;
    bottom: calc(0px - var(--spacing-huge) - 3px);
    background-color: ${colors.borderLight};
  }

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--spacing-xxxlrg) + 1px);

    &::after {
      display: none;
    }
  }
`;

const categoryName = css`
  grid-column: 2 / span 8;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-tiny);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-lrg);

  color: ${colors.primary};
`;

const title = css`
  grid-column: 2 / span 6;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--spacing-xxlrg);

  @media ${breakpoints.medium} {
    grid-column: 2 / span 8;
    font-size: var(--font-size-xxLarge);
    margin-bottom: calc(var(--unit) * 10);
  }

  @media ${breakpoints.large} {
    grid-column: 2 / span 6;
    font-size: var(--font-size-xxxLarge);
    line-height: calc(var(--unit) * 14);
    margin-bottom: calc(var(--unit) * 10);
  }
`;

const imageWrapper = css`
  @media ${breakpoints.medium} {
    padding: var(--unit);
    margin-right: calc(var(--unit) * 5);
    max-width: calc(var(--unit) * 130);
  }

  @media ${breakpoints.large} {
    margin-right: calc(var(--unit) * 10);
  }
`;

const imageTitle = css`
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-base);
  letter-spacing: -1px;

  @media ${breakpoints.medium} {
    padding: var(--unit);
    margin-right: calc(var(--unit) * 5);
    max-width: calc(var(--unit) * 130);
  }

  @media ${breakpoints.large} {
    padding: var(--unit);
    margin-right: calc(var(--unit) * 10);
    max-width: calc(var(--unit) * 130);
  }
`;

const image = css`
  width: 100%;
  height: calc(var(--unit) * 100);
  margin-bottom: var(--spacing-huge);
  object-fit: cover;
  cursor: pointer;

  @media ${breakpoints.tiny} {
    height: calc(var(--unit) * 100);
  }

  @media ${breakpoints.medium} {
    height: calc(var(--unit) * 70);
  }

  @media ${breakpoints.large} {
    height: auto;
    max-width: calc(var(--unit) * 125);
    aspect-ratio: 2/3;
  }
`;

const button = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const galleryButtonClose = css`
  position: fixed;
  z-index: 2;
  right: var(--spacing-xxlrg);
  top: var(--spacing-xlrg);

  background-color: transparent;
  border: none;

  @media ${breakpoints.medium} {
    right: calc(var(--unit) * 17);
    top: calc(var(--unit) * 12);
  }

  &:hover svg {
    fill: ${colors.primary};
  }
`;

const galleryWrapper = css`
  position: relative;
`;

const separator = css`
  display: none;
  height: 2px;
  background-color: ${colors.borderLight};
  z-index: -1;

  @media ${breakpoints.medium} {
    display: block;
    grid-column: 3 / span 9;
    margin-right: calc(var(--unit) * 5);
  }

  @media ${breakpoints.large} {
    margin-right: calc(var(--unit) * 10);
  }
`;

export const curiositiesStyles = {
  root,
  categoryName,
  title,
  image,
  imageTitle,
  imageWrapper,
  sliderWrapper,
  button,
  galleryButtonClose,
  galleryWrapper,
  separator,
};
