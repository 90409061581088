import { SeoTags } from 'components';
import { getSrc } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useImages } from 'hooks';
import React from 'react';
import {
  AboutUs,
  Curiosities,
  HomeVideos,
  FeaturedBlogPosts,
  HomeHeader,
  Offers,
} from '..';
import { homePageStyles } from './HomePage.styles';

export const HomePage: React.FC = () => {
  const { headerImage } = useImages();
  const { formatMessage } = useIntl();

  return (
    <>
      <SeoTags
        title={formatMessage({ id: 'home.seo.title' })}
        description={formatMessage({ id: 'home.seo.description' })}
        imagePath={getSrc(headerImage)}
      />
      <div css={homePageStyles.wrapper}>
        <HomeHeader />
        <Offers />
        <HomeVideos />
        <AboutUs />
        <Curiosities />
        <FeaturedBlogPosts />
      </div>
    </>
  );
};
