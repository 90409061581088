import { css } from '@emotion/react';
import { colors, breakpoints } from 'style';

const root = css`
  margin-bottom: calc(var(--unit) * 36);

  @media ${breakpoints.large} {
    margin-bottom: calc(var(--unit) * 58);
  }
`;

const categoryName = css`
  grid-column: 2 / span 10;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-tiny);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-lrg);

  color: ${colors.primary};
`;

const title = css`
  grid-column: 2 / span 10;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxLarge);
    margin-bottom: calc(var(--unit) * 10);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-xxxLarge);
    line-height: calc(var(--unit) * 14);
    margin-bottom: calc(var(--unit) * 13);
  }
`;

const sliderWrapper = css`
  display: flex;
  position: relative;
  width: 100%;
  grid-column: 2 / span 10;
  margin-bottom: 0;

  &::after {
    position: absolute;
    content: '';
    height: 2px;
    right: 0;
    width: 80%;
    bottom: calc(0px - var(--spacing-huge) - 3px);
    background-color: ${colors.borderLight};
  }

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--spacing-xxxlrg) + 1px);

    &::after {
      display: none;
    }
  }
`;

const separator = css`
  display: none;

  height: 2px;
  background-color: ${colors.borderLight};
  z-index: -1;

  @media ${breakpoints.medium} {
    display: block;
    grid-column: 3 / span 9;
    margin-right: 40px;
  }
`;

const offerWrapper = css`
  padding-right: 0;

  @media ${breakpoints.medium} {
    padding-right: calc(var(--unit) * 10);
  }
`;

export const offersStyles = {
  root,
  title,
  categoryName,
  sliderWrapper,
  separator,
  offerWrapper,
};
