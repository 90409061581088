import { css } from '@emotion/react';
import { colors, breakpoints } from 'style';

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: calc(var(--unit) * 22);

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-regular);
`;

const imageWrapper = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 22);
  }
`;

const image = css`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
`;

const overlay = css`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;

  background-color: ${colors.black};
  opacity: 0.3;
`;

const logo = css`
  position: absolute;
  left: var(--spacing-sml);
  bottom: calc(var(--unit) * 12);
  height: calc(var(--unit) * 14);
  z-index: 1;

  @media ${breakpoints.medium} {
    height: calc(var(--unit) * 32);
    left: var(--spacing-huge);
    bottom: var(--spacing-xxlrg);
  }
`;

const logoDescription = css`
  position: absolute;

  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);

  width: calc(var(--unit) * 60);
  left: calc(var(--unit) * 4);
  bottom: var(--spacing-xxxlrg);

  color: ${colors.white};
  z-index: 1;

  @media ${breakpoints.tiny} {
    width: calc(var(--unit) * 75);
  }

  @media ${breakpoints.medium} {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);

    width: auto;
    left: calc(var(--unit) * 13);
    bottom: var(--spacing-huge);
  }
`;

const title = css`
  max-width: calc(var(--unit) * 80);

  font-size: var(--font-size-xxLarge);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xxxLarge);
  text-align: center;

  color: ${colors.white};

  @media ${breakpoints.medium} {
    max-width: calc(var(--unit) * 160);

    font-size: var(--font-size-huge);
    line-height: var(--line-height-huge);
  }
`;

const description = css`
  grid-column: 2 / span 8;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-large);

  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: calc(var(--unit) * 12);

    margin-bottom: calc(var(--unit) * 12);
  }
`;

const summaryWrapper = css`
  grid-column: 7 / span 5;

  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: ${colors.textLight};
`;

const imageSpecial = css`
  grid-column: 2 / span 4;
  margin-bottom: var(--spacing-huge);
  width: 100%;
  object-fit: cover;

  @media ${breakpoints.medium} {
    margin-bottom: 0;
  }
`;

const imagePrimary = css`
  width: 100%;
  height: 100%;
`;

const imageSecondary = css`
  width: 100%;
  height: 100%;
  max-height: calc(var(--unit) * 125);
`;

const gap = css`
  margin-bottom: var(--spacing-huge);
`;

export const homeHeaderStyles = {
  title,
  wrapper,
  imageWrapper,
  image,
  logo,
  logoDescription,
  overlay,
  description,
  summaryWrapper,
  imageSpecial,
  imagePrimary,
  imageSecondary,
  gap,
};
