import { Grid, SliderComponent } from 'components';
import { motion } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { homeVideosStyles } from './HomeVideos.style';

export const HomeVideos: React.FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <section ref={ref}>
      <Grid css={homeVideosStyles.root} type="page">
        <div css={homeVideosStyles.sliderWrapper}>
          <SliderComponent
            itemsPerSlide={1}
            slidesToScroll={1}
            numberOfItems={10}
          >
            <motion.div
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeIn', duration: 0.6 }}
              css={homeVideosStyles.videoWrapper}
            >
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/BJJKi7Y61ug"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </motion.div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Hoz21_3nEXA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/D61Fji416a8"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ErKJjrtCI-k"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/5gYFw_6Tyes"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ZZnahWOfBrs"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ylrXz1vlCrI"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/UC5mKQEaFXg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/5kvWWS543v8"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div css={homeVideosStyles.videoWrapper}>
              <iframe
                loading="lazy"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/vCQ0CvKMR3o"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </SliderComponent>
        </div>
        <div css={homeVideosStyles.separator}></div>
      </Grid>
    </section>
  );
};
