import { css } from '@emotion/react';
import { breakpoints } from 'style';

const wrapper = css`
  margin: 0 auto;

  @media ${breakpoints.medium} {
    padding: 0;
  }
`;

export const homePageStyles = {
  wrapper,
};
