import { Loading, loadingStyles } from 'components/loading';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { usePrismicImages } from 'hooks';
import React from 'react';
import { singleOfferStyles } from './SingleOffer.style';

interface Props {
  document: ProgramItem;
}

export const SingleOffer: React.FC<Props> = ({ document }) => {
  const { programQuery } = usePrismicImages();
  const image = programQuery.edges.find(
    (edge: { node: { uid: string } }) =>
      edge.node.uid === document.node._meta.uid,
  )?.node.data.program_images[0].image;
  const imageData = getImage(image);

  if (!document)
    return (
      <div css={loadingStyles.loadingWrapper}>
        <Loading />
      </div>
    );

  return (
    <div key={document.node._meta.uid} css={singleOfferStyles.wrapper}>
      {imageData ? (
        <GatsbyImage
          loading="lazy"
          image={imageData}
          alt={document.node.program_images[0].image.alt || ''}
          css={singleOfferStyles.image}
        />
      ) : (
        <img
          loading="lazy"
          src={document.node.program_images[0].image.url}
          alt={document.node.program_images[0].image.alt}
          css={singleOfferStyles.image}
        />
      )}
      <p css={singleOfferStyles.categoryName}>
        /{' '}
        <FormattedMessage
          id={`categories.${document.node.package_type.toLowerCase()}`}
        />
      </p>
      <h2 css={singleOfferStyles.title}>{document.node.title[0].text}</h2>
    </div>
  );
};
