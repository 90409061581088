import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const wrapper = css`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  transition: 0.4s ease-out;

  @media ${breakpoints.medium} {
    padding: calc(var(--unit) * 0.5);
    max-width: calc(var(--unit) * 102);
  }

  &:hover {
    transform: translateY(-10px);
  }
`;

const image = css`
  width: 100%;
  height: calc(var(--unit) * 70);
  margin-bottom: var(--spacing-huge);
  object-fit: cover;

  @media ${breakpoints.medium} {
    height: calc(var(--unit) * 60);
  }

  @media ${breakpoints.large} {
    height: calc(var(--unit) * 102);
  }
`;

const categoryName = css`
  font-size: var(--font-size-small);
  line-height: var(--line-height-tiny);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-lrg);

  color: ${colors.primary};
`;

const title = css`
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  margin-bottom: var(--spacing-lrg);
  font-weight: var(--font-weight-regular);
  color: ${colors.black};
`;

const description = css`
  overflow: hidden;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: ${colors.textLight};
  max-height: calc(var(--unit) * 12);
  margin-bottom: var(--spacing-lrg);

  @media ${breakpoints.medium} {
    letter-spacing: -1px;
  }
`;

const offerDescription = css`
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
`;

const offerNumber = css`
  font-size: var(--font-size-large);
  line-height: var(--line-height-medium);
  font-weight: var(--font-weight-custom500);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-medium);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-large);
  }
`;

const anchor = css`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const singleOfferStyles = {
  title,
  wrapper,
  image,
  description,
  categoryName,
  anchor,
  offerNumber,
  offerDescription,
};
