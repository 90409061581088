import { Grid } from 'components/grid';
import React from 'react';
import { homeHeaderStyles } from './HomeHeader.styles';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { useImages } from 'hooks';
import { motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import InView from 'react-intersection-observer';

export const HomeHeader: React.FC = () => {
  const { formatMessage } = useIntl();
  const { headerImage, homeImage, interregLogoGhost } = useImages();
  const headerImageData = getImage(headerImage);
  const homeImageData = getImage(homeImage);

  return (
    <div css={homeHeaderStyles.wrapper}>
      <div css={homeHeaderStyles.imageWrapper}>
        {headerImageData && (
          <GatsbyImage
            image={headerImageData}
            alt={formatMessage({
              id: 'images.titles.slavonia-baranya',
            })}
            css={homeHeaderStyles.image}
            loading="lazy"
          />
        )}
        <div css={homeHeaderStyles.overlay}></div>
        <motion.h1
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: 1, y: '0' }}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
          css={homeHeaderStyles.title}
        >
          <FormattedMessage id="home.header.title" />
        </motion.h1>
        <motion.img
          loading="lazy"
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: 1, y: '0' }}
          transition={{ ease: 'easeIn', duration: 0.6 }}
          src={getSrc(interregLogoGhost)}
          alt={formatMessage({
            id: 'images.titles.logo',
          })}
          css={homeHeaderStyles.logo}
        />
        <motion.p
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: 1, y: '0' }}
          transition={{ ease: 'easeInOut', duration: 0.9 }}
          css={homeHeaderStyles.logoDescription}
        >
          <FormattedMessage id="home.header.logo-description" />
        </motion.p>
      </div>
      <div>
        <Grid type="page">
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.p
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
                transition={{ ease: 'easeIn', duration: 0.6 }}
                css={homeHeaderStyles.description}
              >
                <FormattedMessage id="home.header.description" />
              </motion.p>
            )}
          </InView>
          <InView threshold={0.2} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
                transition={{ ease: 'easeIn', duration: 0.6 }}
                css={homeHeaderStyles.imageSpecial}
              >
                {homeImageData && (
                  <GatsbyImage
                    image={homeImageData}
                    alt={formatMessage({
                      id: 'images.titles.vineyard',
                    })}
                    loading="lazy"
                    css={homeHeaderStyles.imageSecondary}
                  />
                )}
              </motion.div>
            )}
          </InView>
          <div css={homeHeaderStyles.summaryWrapper}>
            <InView threshold={0.4} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: '40px' }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? '0' : '40px',
                  }}
                  transition={{ ease: 'easeIn', duration: 0.6, delay: 0.4 }}
                  css={homeHeaderStyles.gap}
                >
                  <FormattedMessage id="home.header.paragraph-general" />
                </motion.p>
              )}
            </InView>
            <InView threshold={0.4} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: '40px' }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? '0' : '40px',
                  }}
                  transition={{ ease: 'easeIn', duration: 0.6, delay: 0.4 }}
                  css={homeHeaderStyles.gap}
                >
                  <FormattedMessage id="home.header.paragraph-vacation" />
                </motion.p>
              )}
            </InView>
          </div>
        </Grid>
      </div>
    </div>
  );
};
