import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const root = css`
  margin-bottom: calc(var(--unit) * 50);
`;

const categoryName = css`
  grid-column: 2 / span 3;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-tiny);
  letter-spacing: 2px;
  margin-bottom: var(--spacing-lrg);
  text-transform: uppercase;

  color: ${colors.primary};
`;

const title = css`
  grid-column: 2 / span 7;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxLarge);
    margin-bottom: calc(var(--unit) * 10);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-xxxLarge);
    line-height: calc(var(--unit) * 14);
    margin-bottom: calc(var(--unit) * 12);
  }
`;

const contentWrapper = (colStart: number, colSpan: number) => css`
  @media ${breakpoints.medium} {
    grid-column: ${colStart} / span ${colSpan};
  }
`;

export const featuredBlogPostsStyles = {
  root,
  categoryName,
  title,
  contentWrapper,
};
