import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const root = css`
  margin-bottom: calc(var(--unit) * 34);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 45);
  }

  @media ${breakpoints.large} {
    margin-bottom: calc(var(--unit) * 58);
  }
`;

const categoryName = css`
  grid-column: 2 / span 8;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-tiny);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    margin-bottom: var(--spacing-lrg);
  }

  color: ${colors.primary};
`;

const title = css`
  grid-column: 2 / span 6;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxLarge);
    margin-bottom: calc(var(--unit) * 10);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-xxxLarge);
    line-height: calc(var(--unit) * 12);
  }
`;

const sliderWrapper = css`
  position: relative;
  width: 100%;
  grid-column: 2 / span 6;

  &::after {
    position: absolute;
    content: '';
    height: 2px;
    right: 0;
    width: 80%;
    bottom: calc(0px - var(--spacing-huge) - 3px);
    background-color: ${colors.borderLight};
  }

  @media ${breakpoints.medium} {
    &::after {
      display: none;
    }
  }
`;

export const testimonialsStyles = {
  root,
  title,
  categoryName,
  sliderWrapper,
};
