import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const root = css`
  font-family: var(--font-family-secondary);
  margin-bottom: calc(var(--unit) * 22);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 40);
  }
`;

const categoryName = css`
  grid-column: 2 / span 8;
  font-size: var(--font-size-small);
  line-height: var(--line-height-tiny);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    margin-bottom: var(--spacing-lrg);
  }

  color: ${colors.primary};
`;

const title = css`
  grid-column: 2 / span 4;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--spacing-xxlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxLarge);
    margin-bottom: calc(var(--unit) * 10);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-xxxLarge);
    line-height: calc(var(--unit) * 14);
  }
`;

const description = css`
  grid-column: 7 / span 5;
  font-size: var(--font-size-base);
  line-height: var(--line-height-medium);
  margin-bottom: calc(var(--unit) * 12);

  color: ${colors.textLight};
`;

const image = css`
  width: 100%;

  @media ${breakpoints.medium} {
    height: 100vh;
    object-fit: cover;
  }
`;

export const aboutUsStyles = {
  root,
  categoryName,
  title,
  description,
  image,
};
